import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BtwPipe, SafeHtmlPipe } from '@studiohyperdrive/ngx-utils';

import { ImageComponent } from '@cjm/shared/ui/common';
import { InputWrapperComponent } from '@cjm/shared/ui/forms';
import { OneLineAddressPipe } from '@cjm/shared/utils';

import { CompanyEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';

@Component({
	selector: 'cjm-company-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ImageComponent,
		InputWrapperComponent,
		DatePipe,
		SafeHtmlPipe,
		BtwPipe,
		OneLineAddressPipe,
		TranslateModule
	]
})
export class CompanyInfoComponent {
	public readonly i18nKeys = sharedCompanyI18nKeys.Companies.Info;

	@HostBinding('class.c-company-info-card') public rootClass = true;

	/**
	 * Whether the user is the owner of the company.
	 *
	 * Default value is `false`.
	 */
	@Input() public isUserCompany: boolean = false;
	/**
	 * The company data.
	 */
	@Input() public company: CompanyEntity | Partial<CompanyEntity>;
	/**
	 * Whether the public data text has to be hidden.
	 *
	 * Default value is `false`.
	 */
	@Input() public hideText: boolean = false;
	/**
	 * The title to display.
	 *
	 * Default value is translation string.
	 */
	@Input() public title: string = this.i18nKeys.Title;
	/**
	 * Whether the title should be displayed.
	 *
	 * Default value is `true`.
	 */
	@Input() public showTitle: boolean = true;
}
